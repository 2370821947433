<template>
  <Card class="mb-3">
    <template #content>
      <div class="flex align-items-center mb-5">
        <StatusFilter
          :statusesList="paymentsStatuses"
          title="Record status"
          :isLoading="isLoading"
          :currentSelected="requestParams.paymentsStatus"
          @onStatusChange="changeParams('paymentsStatus', $event)" />
        <div class="p-input-icon-left p-input-icon-right ml-auto">
          <i class="pi pi-search" />
          <i v-show="isLoading" class="pi pi-spin pi-spinner" />
          <InputText
            :modelValue="requestParams.searchText"
            placeholder="Search payments"
            @input="changeParams('searchText', $event.target.value.trim())" />
        </div>
      </div>
      <div class="flex w-full mb-2 relative">
        <Tag
          v-show="isListFiltered"
          class="cursor-pointer absolute left-0"
          style="bottom: -33px"
          icon="pi pi-times"
          severity="danger"
          value="Reset all filters"
          @click="$emit('onResetAllFilters')" />
      </div>
    </template>
  </Card>
</template>

<script setup>
import { computed, reactive } from "vue";
import Card from "primevue/card";
import Tag from "primevue/tag";
import InputText from "primevue/inputtext";

import StatusFilter from "@/components/elements/StatusFilter.vue";

const $props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  requestParams: {
    type: Object,
    required: true,
  },
});

const $emit = defineEmits(["paramsUpdated", "onResetAllFilters"]);

let isListFiltered = computed(() => {
  return Object.keys($props.requestParams).some((key) => {
    return !!$props.requestParams[key];
  });
});

const paymentsStatuses = reactive([
  { title: "All", value: "" },
  { title: "Matched", value: "matched" },
  { title: "Unmatched", value: "unmatched" },
]);

const changeParams = (params, value) => {
  $emit("paramsUpdated", { params, value });
};
</script>
<style scoped></style>
