<template>
  <PageHeader>
    <PaymentsActions
      :selectedPayments="selectedPayments"
      @onLoading="isLoading = $event"
      @onActionFinished="selectedPayments = []" />
  </PageHeader>
  <PaymentsFilters
    :requestParams="requestParams"
    :isLoading="isLoading"
    @onResetAllFilters="resetAllFilters"
    @paramsUpdated="updateParams" />
  <PaymentsTable
    v-model:selection="selectedPayments"
    :payments="payments"
    :isLoading="isLoading"
    :perPage="pagination.perPage"
    :allItems="pagination.allItems"
    :page="pagination.page"
    :sortingParam="requestParams.sortingParam"
    @page="updatePagination"
    @onParamsUpdated="updateParams">
    <template #tableHeader>
      <PaymentsTableActions
        :selectedPayments="selectedPayments"
        :isLoading="isLoading"
        @onLoading="isLoading = $event"
        @onActionFinished="getPayments" />
    </template>
  </PaymentsTable>
</template>

<script setup>
import PaymentsActions from "@/components/payments/PaymentsActions";
import PageHeader from "@/components/elements/PageHeader";
import PaymentsFilters from "@/components/payments/PaymentsFilters";
import PaymentsTable from "@/components/payments/PaymentsTable";
import PaymentsTableActions from "@/components/payments/PaymentsTableActions";

import { onBeforeMount, reactive, ref, watch } from "vue";
import { useUserStore } from "@/store";
import { MATCHING_STATUSES } from "@/utils/сonsts";
import { getPaymentsRequest } from "@/api/paymentsService";

const $userStore = useUserStore();

const defaultParams = {
  paymentsStatus: "",
  searchText: null,
  sortingColumn: null,
  sortingParam: null,
};

let isLoading = ref(false);
let payments = ref([]);
let requestParams = reactive({ ...defaultParams });
let pagination = reactive({
  perPage: 10,
  allItems: 10,
  page: 1,
  allPage: 0,
});
let selectedPayments = ref([]);

const resetAllFilters = () => {
  Object.assign(requestParams, defaultParams);
  getPayments();
};

const updateParams = ({ params, value }) => {
  if (requestParams[params] === value) {
    return;
  }
  requestParams[params] = value;
  if (["startDate", "sortingColumn"].includes(params)) {
    return;
  }
  getPayments();
};

const updatePagination = ({ page, rows }) => {
  if (pagination.perPage !== rows) {
    pagination.perPage = rows;
    getPayments();
    return;
  }
  if (pagination.page !== page + 1) {
    pagination.page = page + 1;
    getPayments();
  }
};

const getPayments = async () => {
  try {
    isLoading.value = true;
    selectedPayments.value = [];
    const { data, pagination: updatedPagination } = await getPaymentsRequest({
      ...requestParams,
      page: pagination.page,
      perPage: pagination.perPage,
    });
    pagination.page = +updatedPagination.page;
    pagination.perPage = +updatedPagination.perPage;
    pagination.allItems = +updatedPagination.allItems;
    pagination.allPage = +updatedPagination.allPage;
    payments.value = data;
  } catch (e) {
    console.warn("getInvoices error", e);
  } finally {
    isLoading.value = false;
  }
};

watch(
  () => $userStore.paymentMatchingStatus,
  (value) => {
    value === MATCHING_STATUSES.FINISHED && getPayments();
  }
);

onBeforeMount(() => {
  getPayments();
});
</script>

<style scoped></style>
