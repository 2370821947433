<template>
  <BasicTable
    v-model:selection="selected"
    :columns="columns"
    dataKey="id"
    :value="payments"
    responsiveLayout="scroll"
    :rowHover="true"
    :lazy="true"
    :alwaysShowPaginator="false"
    :loading="isLoading"
    :perPage="perPage"
    :allItems="allItems"
    @page="$emit('page', $event)"
    @sort="onChangeSort">
    <template #header>
      <slot name="tableHeader" />
    </template>
    <Column selectionMode="multiple" headerStyle="width: 3em" />
    <Column field="status" header="Status">
      <template #body="{ data: { status } }">
        <Btn
          class="p-button-rounded p-button-sm capitalize"
          :class="
            status === 'matched'
              ? 'bg-gray-100 text-bluegray-500 border-gray-100'
              : 'p-button-secondary'
          "
          :label="status" />
      </template>
    </Column>
  </BasicTable>
</template>
<script setup>
import BasicTable from "@/components/elements/BasicTable.vue";
import Btn from "primevue/button";
import Column from "primevue/column";

import { ref } from "vue";

const $props = defineProps({
  payments: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  perPage: {
    type: Number,
    default: 10,
  },
  allItems: {
    type: Number,
    default: 10,
  },
  sortingParam: {
    default: null,
  },
});

const $emit = defineEmits(["onParamsUpdated", "page"]);

const columns = [
  {
    header: "Amount (CHF)",
    field: "amountDue",
    sortable: true,
  },
  {
    header: "Customer Name",
    field: "customerName",
  },
  {
    header: "Reference number",
    field: "number",
    sortable: true,
  },
  {
    header: "Comments",
    field: "comments",
  },
  {
    header: "Address",
    field: "address",
  },
  {
    header: "Date",
    field: "created",
    sortable: true,
  },
];

let selected = ref([]);

const onChangeSort = ({ sortField, sortOrder }) => {
  $emit("onParamsUpdated", { params: "sortingColumn", value: sortField });
  $emit("onParamsUpdated", {
    params: "sortingParam",
    value: sortOrder ? (sortOrder === 1 ? "DESC" : "ASC") : null,
  });
};
</script>
<style scoped></style>
