<template>
  <Dialog
    class="upload-modal"
    header="Upload XML"
    modal
    :draggable="false"
    :visible="visible"
    :closable="!isLoading"
    @update:visible="$emit('update:visible', $event)">
    <div
      v-if="isUploadFinished"
      class="flex flex-column align-items-center justify-content-center h-full px-2 mt-auto">
      <p>XML download complete, want to run payment matching?</p>
      <RunPaymentsMatchingBtn isSimple @matchingStarted="$emit('update:visible', false)" />
    </div>
    <FileUpload
      v-else
      name="paymentXMLUpload"
      :fileLimit="50"
      :multiple="true"
      :maxFileSize="10000000"
      :showCancelButton="false"
      :preview-width="0"
      accept="text/xml"
      :customUpload="true"
      @uploader="uploadFiles">
      <template #empty>
        <ProgressSpinner v-if="isLoading" />
        <template v-else>
          <div
            class="h-full flex flex-column w-full"
            :class="isUploadFinished ? 'justify-content-end' : 'justify-content-center'">
            <div
              class="flex align-content-center justify-content-center"
              :class="isUploadFinished ? 'mt-auto' : ''">
              <span class="pi pi-file text-2xl mr-1" />
              <div>
                <h3 class="text-primary font-semibold m-0">
                  Click to choose a file or drag and drop here
                </h3>
                <p class="m-0 text-sm text-gray-600">XML format only</p>
              </div>
            </div>
          </div>
        </template>
      </template>
    </FileUpload>
  </Dialog>
</template>

<script setup>
import Dialog from "primevue/dialog";
import FileUpload from "primevue/fileupload";
import ProgressSpinner from "primevue/progressspinner";
import RunPaymentsMatchingBtn from "@/components/payments/RunPaymentsMatchingBtn";

import { useToast } from "primevue/usetoast";
import { uploadXmlRequest } from "@/api";
import { ref } from "vue";

defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
});
defineEmits(["update:visible"]);

const $toast = useToast();

let isLoading = ref(false);
let isUploadFinished = ref(false);

const uploadFiles = async (event) => {
  isLoading.value = true;
  $toast.add({
    severity: "info",
    summary: "Uploading files started",
    detail: "Please wait till the upload finish",
    life: 5000,
  });
  const { success, message } = await uploadXmlRequest(event);
  isLoading.value = false;
  isUploadFinished.value = true;
  if (success) {
    $toast.add({ severity: "info", summary: "Success", detail: message, life: 10000 });
    return;
  }
  $toast.add({ severity: "error", summary: "Error", detail: message, life: 10000 });
};
</script>

<style lang="scss">
.upload-modal {
  max-width: 75%;
  width: 100%;
  height: 600px;
  max-height: 600px;
  .p-dialog-header {
    padding: 12px 24px;
    border-bottom: 1px solid var(--surface-200);
    .p-dialog-title {
      font-size: 24px;
    }
  }
  .p-dialog-content {
    padding: 0;
    height: 100%;
  }
  .p-fileupload {
    height: 87%;
    .p-fileupload-content {
      height: 100%;
      overflow-y: auto;
      .p-fileupload-empty {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
