<template>
  <Btn
    class="bg-purple-500 border-purple-500"
    :label="paymentMatchingBtnText"
    :disabled="!isSimple && $userStore.isMatchingStarted"
    :loading="!isSimple && $userStore.isMatchingStarted"
    @click="startMatching" />
</template>

<script setup>
import Btn from "primevue/button";
import { computed } from "vue";
import { useToast } from "primevue/usetoast";
import { useUserStore } from "@/store";
import { startPaymentsMatchingRequest } from "@/api/paymentsService";

const $props = defineProps({
  isSimple: {
    type: Boolean,
    default: false,
  },
});
const $emit = defineEmits(["matchingStarted"]);

const $toast = useToast();
const $userStore = useUserStore();

const paymentMatchingBtnText = computed(() => {
  const text = "Run payments matching";
  if ($props.isSimple) {
    return text;
  }
  return $userStore.isMatchingStarted ? `Matching in progress` : text;
});

const startMatching = async () => {
  const { message, success } = await startPaymentsMatchingRequest();
  $toast.add({
    severity: success ? "success" : "error",
    summary: success ? "Success" : "Error",
    detail: message,
    life: 5000,
  });
  $emit("matchingStarted");
};
</script>

<style scoped></style>
