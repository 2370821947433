<template>
  <div class="flex align-items-center">
    <RunPaymentsMatchingBtn class="mr-2" />
    <Btn
      class="bg-yellow-500 border-yellow-500"
      label="Upload XML file"
      :disabled="$userStore.isMatchingStarted"
      @click="isUploadModal = true" />
    <UploadModal v-model:visible="isUploadModal" />
  </div>
</template>

<script setup>
import Btn from "primevue/button";
import UploadModal from "@/components/payments/UploadModal";
import RunPaymentsMatchingBtn from "@/components/payments/RunPaymentsMatchingBtn";
import { ref } from "vue";
import { useUserStore } from "@/store";

const $userStore = useUserStore();

let isUploadModal = ref(false);
</script>

<style scoped></style>
