// Payments
import { getItemsFunction } from "@/utils/apiHelper";
import { dateConvertor, fileSaver, getExportFileName } from "@/utils";
import axiosInstance from "@/api/apiConfig";

export const getPaymentsRequest = async (requestParams) => {
  const { data, pagination } = await getItemsFunction("payments/list", requestParams);
  return {
    pagination,
    data: data.map(
      ({
        id,
        status,
        amountChf,
        referenceNumber,
        dateCreate,
        info,
        address,
        dateUpload,
        customerName,
      }) => ({
        id,
        status,
        amountDue: amountChf,
        customerName,
        number: referenceNumber,
        comments: info,
        address,
        created: dateConvertor(dateCreate, false, "."),
        dateUpload,
      })
    ),
  };
};
export const changePaymentsStatusRequest = async (paymentsIds, action) => {
  try {
    return await axiosInstance.post("payments/delete/or/switch/status", {
      ids: paymentsIds,
      action,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const exportPaymentsRequest = async (ids) => {
  try {
    const paymentsXml = await axiosInstance.post("payments/export", { ids });
    const blobURL = window.URL.createObjectURL(new Blob([paymentsXml], { type: "text/csv" }));
    fileSaver(blobURL, getExportFileName());
  } catch (e) {
    console.log("e", e);
  }
};
export const startPaymentsMatchingRequest = async () => {
  try {
    return await axiosInstance.post("start-matching");
  } catch (e) {
    return { success: false, message: e.message || "System Error" };
  }
};
