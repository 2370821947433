<template>
  <div class="flex align-content-center">
    <Btn
      v-for="{ label, command, disabled } in actions"
      :key="label"
      class="p-button-secondary p-button-outlined border-round-lg mr-2"
      :label="label"
      :disabled="!selectedPayments.length || disabled"
      :loading="isLoading"
      @click="command" />
  </div>
</template>

<script setup>
import Btn from "primevue/button";
import { useToast } from "primevue/usetoast";
import { computed } from "vue";
import { changePaymentsStatusRequest, exportPaymentsRequest } from "@/api/paymentsService";

const $props = defineProps({
  selectedPayments: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});
const $emit = defineEmits(["onLoading", "onActionFinished"]);
const $toast = useToast();

const isMatchingDisabled = computed(() =>
  $props.selectedPayments.every(({ status }) => status === "matched")
);
const isUnmatchingDisabled = computed(() =>
  $props.selectedPayments.every(({ status }) => status === "unmatched")
);

const actions = computed(() => [
  {
    label: "Match",
    disabled: isMatchingDisabled.value,
    command: () => makeAction("make-matched"),
  },
  {
    label: "Unmatch",
    disabled: isUnmatchingDisabled.value,
    command: () => makeAction("make-unmatched"),
  },
  {
    label: "Export",
    command: () => makeAction("export"),
  },
  {
    label: "Delete",
    command: () => makeAction("do-delete"),
  },
]);

const makeAction = async (actionType) => {
  $emit("onLoading", true);
  const paymentsIds = $props.selectedPayments.map(({ id }) => id);
  actionType === "export"
    ? await exportPayments(paymentsIds)
    : await changePaymentsStatus(paymentsIds, actionType);
  $emit("onActionFinished");
  $emit("onLoading", false);
};
const changePaymentsStatus = async (paymentsIds, actionType) => {
  const { success, message } = await changePaymentsStatusRequest(paymentsIds, actionType);
  return $toast.add({
    severity: success ? "success" : "warn",
    summary: success ? "Success" : "Error",
    detail: message,
    life: 5000,
  });
};

const exportPayments = async () => {
  const paymentsIds = $props.selectedPayments.map(({ id }) => id);
  return await exportPaymentsRequest(paymentsIds);
};
</script>

<style scoped></style>
